<template>
  <b-container style="overflow-x: visible" class="border-bottom">
    <b-row class="a-section-title">
      <b-col>
        <h2 class="text-center">
          <span class="w-color-orange z-fancy-underline">
            {{ $t("Naše najnovšie") }}<svg-fancy-underline-icon />
            <img src="/latest.png" class="small-clock" />
          </span>
          <span class="w-color-black"> {{ $t("video kurzy") }}</span>
        </h2>
      </b-col>
    </b-row>
    <b-row>
      <CardsCarousel v-if="courses && courses.length" :cards="courses">
        <template v-slot:card="props">
          <CourseCard
            v-if="props.card._model === 'course'"
            :course="props.card"
          />
          <PathCard v-else :path="props.card" />
        </template>
      </CardsCarousel>
    </b-row>
    <b-row class="mt-3">
      <b-col class="text-center">
        <router-link to="/kurzy">
          <b-button variant="primary" class="a-poppins-btn -medium -wide">{{
            $t("Pozrieť všetky kurzy")
          }}</b-button>
        </router-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    courses: {
      required: true,
    },
  },
  components: {
    CardsCarousel: () => import("/components/CardsCarousel.vue"),
    "svg-fancy-underline-icon": () =>
      import("/assets/icons/fancyUnderline.svg?inline"),
    PathCard: () => import("/components//Path/PathCard.vue"),
    CourseCard: () => import("/components/CourseCard.vue"),
  },
};
</script>

<style lang="scss" scoped>
.small-clock {
  position: absolute;
  height: 1.375rem;
  margin-top: -0.8125rem;
  left: 90%;
}
</style>
